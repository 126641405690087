.background-image {
    background: url(../assets/background.jpg) no-repeat center fixed;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: fixed;
}

/* login  screen*/

input::-ms-clear, input::-ms-reveal {
    display: none;
}

.modal-backdrop {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #00000014 !important;
}

.divcontainer {
    /* height: 250px; */
    padding: 30px 0px 30px 0px;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 3px;
}

.passwordEyeIcon {
    position: relative;
    top: -30px;
    right: -93%;
    cursor: pointer;
    color: rgb(82, 81, 81);
}

.left {
    position: relative;
}

.right {
    position: relative;
}

.course-nameFilter {
    width: 45%;
}

.course-dateFilter {
    width: 25%;
    margin-right: 10px;
}

.course-clearFilter {
    width: 5%;
    text-align: right;
}

@media screen and (max-width:767px) {
    .divcontainer {
        justify-content: space-between;
    }
    .left {
        order: 2;
    }
    .right {
        order: 1;
    }
    .bottom {
        order: 3;
        padding-top: 10px;
    }
}

@media screen and (max-width:430px) {
    .passwordEyeIcon {
        position: relative;
        top: -30px;
        right: -88%;
        cursor: pointer;
        color: rgb(82, 81, 81);
    }
    .course-nameFilter {
        width: 100%;
    }
    .course-dateFilter {
        width: 100%;
        margin-right: 10px;
    }
    .course-clearFilter {
        width: 10%;
        text-align: right;
    }
}

/* login  screen*/

.page-home .card-xl, .page-home .card-lg, .page-home .card-sm, .page-home .card-content-analytics {
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
}

.card {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
    position: relative;
    /* margin: 15px 0; */
}

.text {
    border: #1d2938 solid black;
}

.text-center {
    text-align: center;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .page-course .sortable-placeholder, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .page-courses .sortable-placeholder, .page-course-collection .sortable-placeholder, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.card-recent-courses, .card-recent-lessons {
    height: 235px;
}

.row:before, .row:after {
    /* content: " "; */
    display: table;
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.page-home .card-action .card-content {
    width: 220px;
    margin: auto;
}

.card .card-content {
    position: relative;
}

.card.card-action {
    cursor: pointer;
}

.page-home .card-action, .page-home .card-action>a {
    text-decoration: none;
}

.card-content {
    text-align: center;
}

.block-v-sm {
    padding-top: 4px;
    padding-bottom: 4px;
}

ul, ol {
    margin-top: 0;
    margin-bottom: 12px;
    padding-left: 1.2em;
    list-style-type: none;
}

ul {
    display: block;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.chart-legend-key {
    position: relative;
    padding-left: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-xxl {
    font-size: 64px;
}

.text-center {
    text-align: center;
}

/* .block-xl {
    padding: 24px;
} */

.block-v-xl {
    padding-top: 24px;
    padding-bottom: 24px;
}

.react-card {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
    margin: 15px 0;
    position: relative;
    border-radius: 3px;
}

.react-card .card-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    cursor: pointer;
}

.react-card .card-content .card-thumb {
    min-width: 175px;
    height: 128px;
    background-color: #d7dfe7;
    background-position: center;
    background-size: cover;
    position: relative;
}

.course-image {
    width: 175px;
    height: 128px;
}

.react-card .card-content .card-text {
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 16px;
    height: 128px;
    overflow: hidden;
    position: relative;
    line-height: 1.3;
}

.react-card .card-actions {
    padding: 4px;
}

.hHdVrU {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.course-items-container {
    /* max-height: 80%; */
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.react-wrapper .course-card-item {
    margin: 15px;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    -ms-flex-item-align: center;
    align-self: center;
    z-index: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* navigation */

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5);
    font-weight: 600;
}

.navbar {
    position: fixed !important;
    min-height: 48px;
    border: 1px solid transparent;
    background-color: #223042;
    border-color: #1d2938;
    width: 100%;
    z-index: 400;
}

.icon-btn {
    -o-text-overflow: unset;
    text-overflow: unset;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 7px 12px;
    font-size: 16px;
    line-height: 1.41176;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.heading-editor {
    padding-top: 15px;
    padding-bottom: 15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
    background-color: white;
}

.heading-editor .heading-editor-text-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.heading-editor .heading-editor-text-wrapper>h1 input {
    height: 45px;
}

.btn-xs, .btn-group-xs>.btn {
    padding: 0 6px;
    font-size: 15px;
    line-height: 1.6;
    border-radius: 3px;
}

.heading-editor-publishable {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
}

.icon-ib, .caret, .input-group-addon>.icon, .btn>.icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
}

.form-container {
    display: flex;
}

.form-submit-button {
    padding-top: 30px;
    padding-bottom: 20px;
}

.course-filter-container {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 450px) {
    .react-card .card-content .card-thumb {
        min-width: 125px !important;
        height: 85px !important;
        background-color: #d7dfe7;
        background-position: center;
        background-size: cover;
        position: relative;
    }
    .course-image {
        width: 125px !important;
        height: 85px !important;
    }
}

@media screen and (min-width: 767px) {
    .nav {
        padding-left: 60px !important;
    }
}

@media screen and (max-width: 767px) {
    .nav {
        padding-top: 25px;
    }
    .form-container {
        display: block;
    }
    .input-field {
        padding: 20px 0 !important;
    }
    .form-submit-button {
        padding-top: 30px;
        justify-content: center;
        display: flex;
        padding-bottom: 20px;
    }
}

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link:focus {
    color: rgb(218, 218, 218) !important;
    font-weight: 600;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #fff !important;
    font-weight: 600;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("../assets/menu.png") !important;
    height: 30px;
    width: 30px;
}

/*----------- Toast msg --------------*/

#login {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    /* background-color: #333; */
    background-color: rgba(51, 51, 51, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 45%;
    bottom: 60px;
    font-size: 17px;
}

#login.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    /* background-color: #333; */
    background-color: rgba(51, 51, 51, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.True_False {
    display: flex;
    flex-direction: row;
}

.todos {
    height: 100px;
    width: 150px;
    margin: 10px;
}

.done {
    height: 550px;
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #e4eaf1;
    border: 1px solid #cdd9e4;
    border-radius: 4px;
}

.toggle-input {
    padding: 0;
    margin: 0;
}

.toggle-input>label {
    padding-left: 32px;
    display: block;
}

.radio>label, .checkbox>label {
    position: relative;
}

.toggle-wrap {
    position: absolute;
    overflow: hidden;
    margin-left: -36px;
    margin-top: -3px;
    width: 1em;
    height: 1em;
    padding: 4px;
    box-sizing: content-box;
    text-align: center;
    font-size: 20px;
}

.radio label, .checkbox label {
    min-height: 24px;
    padding-left: 32px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}

/* ---------- slider functionality----------- */

.MuiSlider-markLabel {
    top: 22px;
    color: rgb(249, 249, 249) !important;
    position: absolute;
    font-size: 0.875rem;
    transform: translateX(-50%);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    white-space: nowrap;
    letter-spacing: 0.01071em;
}

.MuiSlider-markLabelActive {
    color: rgb(255, 255, 255) !important;
}

.MuiSlider-track {
    height: 2px;
    display: block;
    position: absolute;
    border-radius: 1px;
    opacity: 1 !important;
    background-color: currentColor;
}

.MuiSlider-rail {
    width: 100%;
    height: 2px;
    display: block;
    opacity: 1 !important;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
}

.MuiSlider-mark {
    height: 4px !important;
}

.error {
    border: 2px solid #ff00008f !important;
    box-shadow: none!important;
}

.input-error {
    color: red;
    display: none;
}

.placeholder-card {
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 40px;
    margin: 40px;
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

/* Assessor */

.inputbox {
    margin-top: 28px;
}

@media screen and (max-width: 767px) {
    .inputbox {
        margin-top: 0px;
    }
    .delete_button {
        padding-top: 30px;
    }
}

.react-calendar {
    width: 100% !important;
    /* height: 300px !important; */
}

.react-calendar button {
    padding: 30px 0px 30px 0px;
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar__navigation {
    height: 100% !important;
    border-bottom: 1px solid #a0a096;
}

.react-calendar__month-view__days__day--weekend {
    color: #000000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}

.react-calendar__tile--active {
    background: #006edc !important;
    color: white !important;
  }

.highlight {
    color:red !important;
    font-weight: bold !important;
    font-size: 25px;
 }

.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 0 !important;
}

.react-time-picker__clock {
    display: none !important;
}

.react-time-picker__clock-button {
    display: none !important;
}

.react-time-picker {
    width: 150px !important;
}

.react-tel-input .form-control {
    height: 38px !important;
    width: 100% !important;
    /* display: none; */
}

.react-tel-input {
    height: 38px !important;
    z-index: 1;
    /* width: 9px !important; */
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-custom-flag-select__select__buttonWrapper___12YOr {
    height: 100%;
    border: 1px solid #ced4da;
    background-color: #fff;
}

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    top: 5px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* unit progress */

.CircularProgressbar {
    width: 100px !important;
    height: 100px !important;
}

/* video progress */

.progress-wrapper {
    width:100%;
}
.progress-wrapper .progress {
    background-color:green;
    width:0%;
    padding:5px 0px 5px 0px;
}