.App {
  display: flex;
  flex-direction: row;
}

.droptarget {
  /* float: center;  */
  width: 120px; 
  height: 120px;
  margin: 15px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.30);
}

.dragVisibility{
  display: none !important;
}