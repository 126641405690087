.level {
    width: 20%;
}

.course-name {
    width: 25%;
    padding-right: 15px;
}

.course-list {
    width: 70%;
}

.country-code {
    width: 20%;
}

.contact-number {
    width: 80%;
}

.dropdown-filter {
    width: 20%;
    padding-left: 10px;
    padding-right: 15px;
}

.learner-paymentPlan{
    width: 58%;
    margin-left: 2%;
}

.learner-notes{
    width: 40%;
}

@media screen and (max-width:767px) {
    .left-content {
        order: 2;
    }
    .right-content {
        order: 1;
    }
    .level {
        width: 30%;
    }
    .course-name {
        width: 40%;
        padding-left: 10px !important;
        padding-right: 0px
    }
    .course-list {
        width: 60%;
    }
    .display-course-title {
        display: none;
    }
    .user-form-container {
        padding: 5px !important;
    }
    .apply-button, .clear-button {
        padding-top: 10px;
        width: 50%;
    }
    .clear-button {
        padding-bottom: 10px;
    }
    .dropdown-filter {
        width: 50%;
        padding-left: 10px;
        padding-right: 15px;
        padding-top: 10px;
    }
    .report-filter{
        padding-left: 0;
        padding-right: 0;
    }
    .learner-paymentPlan{
        width: 100%;
        margin-left: 0 !important;
        margin-top: 30px;
    }
    
    .learner-notes{
        width: 100%;
    }
}

@media screen and (min-width:992px) {
    .added-by {
        text-align: right;
    }
}

@media screen and (max-width:340px) {
    .country-code {
        width: 25%;
    }
    .contact-number {
        width: 75%;
    }
}