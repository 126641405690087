
.puzzleWrap {
  width: 100%;
  
}


/**
* Styles for the puzzle
*/
#puzzle {
  float: left;
 
   width: 100%;
}

#puzzle div {
  width: 100%;
  margin: 0 auto;
}

/* style for each square in the puzzle */
#puzzle .puzzleSquare {
  height: 28px;
  width: 28px;
  text-transform: uppercase;
  background-color: #306a8b;
  border: 0;
  outline: none;
  font: 1em sans-serif;
  color:   #6891ad;
  padding:5px;
}

button::-moz-focus-inner {
  border: none;
  outline: none;
}

/* indicates when a square has been selected */
#puzzle .selected {
  color: #ee5426;
  outline: none;
 
}

/* indicates that the square is part of a word that has been found */ 
#puzzle .found {
  color: #fff;
  /* background-color: #34baeb; */
}

#puzzle .solved {
  color: #ee5426;
}

/* indicates that all words have been found */
#puzzle .complete {
  background-color: green;
}

/**
* Styles for the word list
*/
#words {
  display: none;
  width: 250px;
  color: #6891ad;
  float: left;
  margin-top: 37px;
}

#words ul {
  list-style-type: none;
}

#words li {
  padding:  0 0 7px;
  font: 1em sans-serif;
  display: inline-block;
  width: 100px;
}

/* indicates that the word has been found */
#words .wordFound {
  text-decoration: line-through;
  color: #ee5426;
}

/**
* Styles for the button
*/
#solve {
  margin: 30px 30px 0 40px;
  
  background: transparent;
  color: #ee5426;
  padding: 10px 20px;
  border: 2px solid #ee5426;
  border-radius: 25px;
  opacity: .5;
  transition: opacity .25s ease-in;
  
 
}
