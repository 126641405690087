.draw-container {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#sketch {
    /* border: 10px solid gray; */
    height: 100%;
}

/* p {
    margin: 2rem auto;
} */