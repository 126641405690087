
  
   .playground{
    padding: 35px;
    /* width: 40%; */
    /* margin: 20px auto; */
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    margin-bottom: -15px;
  }
 
 .playground .card {
    width: 48%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: 65px;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 15px;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative;
  }
   .playground .card div {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: 0.6s;
    background: #f4f5f7;
  }
  .playground .card .front {
    font-size: 50px;
    line-height: 120px;
    cursor: pointer;
    color: #b9bfcc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   .playground .card .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    /* line-height: 110px; */
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
   .playground .card .back img {
    vertical-align: middle;
    width: 70%;
  }
   .playground .card.opened {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
   .playground .card.matched {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
   .playground .card.matched .back {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05) inset;
    -webkit-animation: selected .8s 0s ease 1;
            animation: selected .8s 0s ease 1;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    opacity: .2;
  }
  
  @-webkit-keyframes selected {
    0% {
      opacity: .2;
    }
    30% {
      opacity: .5;
    }
    50% {
      opacity: .9;
    }
    70% {
      opacity: .2;
    }
    100% {
      opacity: .3;
    }
  }
  
  @keyframes selected {
    0% {
      opacity: .2;
    }
    30% {
      opacity: .5;
    }
    50% {
      opacity: .9;
    }
    70% {
      opacity: .2;
    }
    100% {
      opacity: .3;
    }
  }